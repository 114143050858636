

//Champions constants
export const ats = ["2104636556710385990", "5867786426589854558", "16566155339920755692",
"3773127936721202968", "8144416923953083888", "5128832264817383489", "16394505611682764",
 "4857544769878781440", "5629836608390158414", "1427404954199089369"]; //champions smart contract addressas

/*export const atsFUNN = ["12311872483963501401", "3366024509486583869", "6078846274390139394",
"11695706840893348645", "1479322154998065270"]; //testnet, champions fun smart contracs */
export const atsFUN = ["17624324386432669321", "8397777419429271813", "3733214586233174071", "18147348141081310664",
 "16017586782550988597", "3878869199477509941", "4034482086454891126", "14550432671197179851", "18440300255636816167", "15129712882761923911"];

export const creator = "2999936507779208272"; // champions SC creator

export const creatorFun = "5219831338759933722" // champions fun SC creator


//Auction constants
export const atsAuction = ["2680159498487926480", "12553641120222397902", "18288230987330115550"]; //8036529174447150734 coin
//export const atsAuction = ["17163765293759263166", "2200060573233813583"]; //testing

export const pastAuctionWinners = [
    {
        at: "7302256417596971354",
        owner: "8035576218347809537",
        price: 700
    },
    {
        at: "12311488945779240052",
        owner: "820256820168033388",
        price: 1150
    },
    {
        at: "12940860845189613495",
        owner: "15054471796918078957",
        price: 4000
    },
    {
        at: "15088739159027627648",
        owner: "15054471796918078957",
        price: 1000
    },
    {
        at: "4677944661084901224",
        owner: "2726459462890839466",
        price: 400
    },
    {
        at: "12864557858778409194",
        owner: "15207231787746818018",
        price: 1000
    },
    {
        at: "8036529174447150734",
        owner: "102196302573724597",
        price: 1005
    },
    {
        at: "1391511593998449720",
        owner: "4781758516073048339",
        price: 500
    },
    {
        at: "8913298276278405040",
        owner: "4781758516073048339",
        price: 1000
    },
    {
        at: "12755642606493738221",
        owner: "12337262194381695767",
        price: 500
    }
]



//General constants
export const EXPLORERFUN = "https://testnet.explorer.burstcoin.network/?action=account&account=";
export const EXPLORER = "https://explorer.burstcoin.network/?action=account&account=";


//export const NODE = "http://testnet.getburst.net:6876";
export const NODEFUN = "http://nivbox.co.uk:6876/";
export const NODE = "https://wallet.burstcoin.ro:443";
//const NODE = 'https://burst-now-proxy.ohager.now.sh';


