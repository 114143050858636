import React, { Component } from "react";
import { composeApi, ContractHelper } from "@burstjs/core";
import { sumNQTStringToNumber } from "@burstjs/util";

import First from "./first";
import Second from "./second";
import Champ from "./thirdChamp";


import imgHeavy from "./data/heavy-d.jpg";
import imgMiddle from "./data/middle-d.jpg";
import imgLight from "./data/light-e.jpg";
import { creatorFun } from "../../constants/const";

//const apiSettings = new ApiSettings(NODE, "burst");

class AppFun extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.api = composeApi(this.props.apiSettings);
    this.ats = this.props.ats;
    this.state = {
      fun: true,
      lang: "eng",
      curBlock: 5500,
      owners: [],
      error: false,
      defender: {
        heavy: {
          heavy: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        },
        cruis: {
          cruis: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        },
        middle: {
          middle: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        },
        welter: {
          welter: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        },
        light: {
          light: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        },
        jheavy: {
          heavy: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        },
        jcruis: {
          cruis: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        },
        jmiddle: {
          middle: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        },
        jwelter: {
          welter: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        },
        jlight: {
          light: "",
          name: null,
          fighting: false,
          unConfTrans: [],
          ownersChain: []
        }
      }
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.atsUpdate();
    this.updateOwnersChain();
    this.timerFighting();
    this.timerList();

    //this.updateLoop()
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timerFighting);
    clearInterval(this.timerList);
  }

  atsUpdate = () =>
    this.ats.map(at =>
      this.api.contract
        .getContract(at)
        .then(this.setInitialDefendrs, this.showError)
    );

  timerFighting = () =>
    setInterval(() => {
      this.atsUpdate(this.ats, this.api);
    }, 5000);

  timerList = () =>
    setInterval(() => {
      this.updateOwnersChain();
    }, 20000);

  updateOwnersChain = () => {
    var i = 0;
    this.ats.forEach(
      at =>
        this.api.account
          .getAccountTransactions(at)
          .then(this.setOwnersChain.bind(null, i++), this.showError) //JJos method better then mine:)
    );
  };

  setOwnersChain = (c, value) => {
    let victories = 1;
    let dict = {};

    for (let i = 0; i < value.transactions.length; i++) {
      var tri = value.transactions[i];
      //console.log(tri);
      if (
        tri.type === 22 &&
        tri.amountNQT !== "0" &&
        tri.recipient !== creatorFun
      ) {
        /* var amount = parseInt(
            tri.amountNQT.substring(0, tri.amountNQT.length - 8)
          );*/
        victories = 1;

        if (dict[tri.recipientRS] != null) {
          victories += dict[tri.recipientRS];
        }
        dict[tri.recipientRS] = victories;
      }
    }
    // Sort and show the holding time
    var items = Object.keys(dict).map(function(key) {
      return [key, dict[key]];
    });
    // Sort the array based on the second element
    items.sort(function(first, second) {
      return second[1] - first[1];
    });
    let champList = "";
    for (let i = 0; i < items.length; i++) {
      if (i > 0) champList += " | ";
      champList +=
        String(i + 1) +
        ". " +
        items[i][0] +
        " (" +
        items[i][1] +
        (items[i][1] === 1
          ? this.props.lang === "eng"
            ? " victory)"
            : "胜利)"
          : this.props.lang === "eng"
          ? " victories)"
          : "胜利)");
    }
    let copyState = { ...this.state }; //this can be redone
    if (c === 0 && this._isMounted) {
      copyState.defender.heavy.ownersChain = champList;
      this.setState({ copyState });
    }
    if (c === 1 && this._isMounted) {
      copyState.defender.cruis.ownersChain = champList;
      this.setState({ copyState });
    }
    if (c === 2 && this._isMounted) {
      copyState.defender.middle.ownersChain = champList;
      this.setState({ copyState });
    }
    if (c === 3 && this._isMounted) {
      copyState.defender.welter.ownersChain = champList;
      this.setState({ copyState });
    }
    if (c === 4 && this._isMounted) {
      copyState.defender.light.ownersChain = champList;
      this.setState({ copyState });
    }
    if (c === 5 && this._isMounted) {
      copyState.defender.jheavy.ownersChain = champList;
      this.setState({ copyState });
    }
    if (c === 6 && this._isMounted) {
      copyState.defender.jcruis.ownersChain = champList;
      this.setState({ copyState });
    }
    if (c === 7 && this._isMounted) {
      copyState.defender.jmiddle.ownersChain = champList;
      this.setState({ copyState });
    }
    if (c === 8 && this._isMounted) {
      copyState.defender.jwelter.ownersChain = champList;
      this.setState({ copyState });
    }
    if (c === 9 && this._isMounted) {
      copyState.defender.jlight.ownersChain = champList;
      this.setState({ copyState });
    }
  };

  setInitialDefendrs = value => {
    const helper = new ContractHelper(value);
    const owner = helper.getVariableAsDecimal(1);
    let copyState = { ...this.state };

    switch (value.at) {
      case this.ats[0]:
        copyState.defender.heavy.heavy = owner;
        this.api.account
          .getAccount(owner)
          .then(result =>
            result.name !== undefined
              ? (copyState.defender.heavy.name = result.name)
              : (copyState.defender.heavy.name = null)
          );
        var balance = sumNQTStringToNumber(value.balanceNQT);
        if (balance > 1) {
          copyState.defender.heavy.fighting = true;
        } else {
          copyState.defender.heavy.fighting = false;
        }
        this.api.account
          .getUnconfirmedAccountTransactions(this.ats[0])
          .then(
            result =>
              (copyState.defender.heavy.unConfTrans =
                result.unconfirmedTransactions)
          );
        

        if (this._isMounted) {
          this.setState({ copyState });
        }

        break;
      case this.ats[1]:
        copyState.defender.cruis.cruis = owner;
        this.api.account
          .getAccount(owner)
          .then(result =>
            result.name !== undefined
              ? (copyState.defender.cruis.name = result.name)
              : (copyState.defender.cruis.name = null)
          );
        balance = sumNQTStringToNumber(value.balanceNQT);
        if (balance > 1) {
          copyState.defender.cruis.fighting = true;
        } else {
          copyState.defender.cruis.fighting = false;
        }
        this.api.account
          .getUnconfirmedAccountTransactions(this.ats[1])
          .then(
            result =>
              (copyState.defender.cruis.unConfTrans =
                result.unconfirmedTransactions)
          );
        

        if (this._isMounted) {
          this.setState({ copyState });
        }
        break;
      case this.ats[2]:
        copyState.defender.middle.middle = owner;
        this.api.account
          .getAccount(owner)
          .then(result =>
            result.name !== undefined
              ? (copyState.defender.middle.name = result.name)
              : (copyState.defender.middle.name = null)
          );
        balance = sumNQTStringToNumber(value.balanceNQT);
        if (balance > 1) {
          copyState.defender.middle.fighting = true;
        } else {
          copyState.defender.middle.fighting = false;
        }
        this.api.account
          .getUnconfirmedAccountTransactions(this.ats[2])
          .then(
            result =>
              (copyState.defender.middle.unConfTrans =
                result.unconfirmedTransactions)
          );
          

          
        if (this._isMounted) {
          this.setState({ copyState });
        }
        break;
      case this.ats[3]:
        copyState.defender.welter.welter = owner;
        this.api.account
          .getAccount(owner)
          .then(result =>
            result.name !== undefined
              ? (copyState.defender.welter.name = result.name)
              : (copyState.defender.welter.name = null)
          );
        balance = sumNQTStringToNumber(value.balanceNQT);
        if (balance > 1) {
          copyState.defender.welter.fighting = true;
        } else {
          copyState.defender.welter.fighting = false;
        }

        this.api.account
          .getUnconfirmedAccountTransactions(this.ats[3])
          .then(
            result =>
              (copyState.defender.welter.unConfTrans =
                result.unconfirmedTransactions)
          );

          
        if (this._isMounted) {
          this.setState({ copyState });
        }
        break;
      case this.ats[4]:
        copyState.defender.light.light = owner;
        this.api.account
          .getAccount(owner)
          .then(result =>
            result.name !== undefined
              ? (copyState.defender.light.name = result.name)
              : (copyState.defender.light.name = null)
          );
        balance = sumNQTStringToNumber(value.balanceNQT);
        if (balance > 1) {
          copyState.defender.light.fighting = true;
        } else {
          copyState.defender.light.fighting = false;
        }
        this.api.account
          .getUnconfirmedAccountTransactions(this.ats[4])
          .then(
            result =>
              (copyState.defender.light.unConfTrans =
                result.unconfirmedTransactions)
          );
          

        if (this._isMounted) {
          this.setState({ copyState });
        }
        break;
        case this.ats[5]:
          copyState.defender.jheavy.heavy = owner;
          this.api.account
            .getAccount(owner)
            .then(result =>
              result.name !== undefined
                ? (copyState.defender.jheavy.name = result.name)
                : (copyState.defender.jheavy.name = null)
            );
          balance = sumNQTStringToNumber(value.balanceNQT);
          if (balance > 1) {
            copyState.defender.jheavy.fighting = true;
          } else {
            copyState.defender.jheavy.fighting = false;
          }
          this.api.account
            .getUnconfirmedAccountTransactions(this.ats[5])
            .then(
              result =>
                (copyState.defender.jheavy.unConfTrans =
                  result.unconfirmedTransactions)
            );
          
  
          if (this._isMounted) {
            this.setState({ copyState });
          }
  
          break;
        case this.ats[6]:
          copyState.defender.jcruis.cruis = owner;
          this.api.account
            .getAccount(owner)
            .then(result =>
              result.name !== undefined
                ? (copyState.defender.jcruis.name = result.name)
                : (copyState.defender.jcruis.name = null)
            );
          balance = sumNQTStringToNumber(value.balanceNQT);
          if (balance > 1) {
            copyState.defender.jcruis.fighting = true;
          } else {
            copyState.defender.jcruis.fighting = false;
          }
          this.api.account
            .getUnconfirmedAccountTransactions(this.ats[6])
            .then(
              result =>
                (copyState.defender.jcruis.unConfTrans =
                  result.unconfirmedTransactions)
            );
          
  
          if (this._isMounted) {
            this.setState({ copyState });
          }
          break;
        case this.ats[7]:
          copyState.defender.jmiddle.middle = owner;
          this.api.account
            .getAccount(owner)
            .then(result =>
              result.name !== undefined
                ? (copyState.defender.jmiddle.name = result.name)
                : (copyState.defender.jmiddle.name = null)
            );
          balance = sumNQTStringToNumber(value.balanceNQT);
          if (balance > 1) {
            copyState.defender.jmiddle.fighting = true;
          } else {
            copyState.defender.jmiddle.fighting = false;
          }
          this.api.account
            .getUnconfirmedAccountTransactions(this.ats[7])
            .then(
              result =>
                (copyState.defender.jmiddle.unConfTrans =
                  result.unconfirmedTransactions)
            );
            
  
            
          if (this._isMounted) {
            this.setState({ copyState });
          }
          break;
        case this.ats[8]:
          copyState.defender.jwelter.welter = owner;
          this.api.account
            .getAccount(owner)
            .then(result =>
              result.name !== undefined
                ? (copyState.defender.jwelter.name = result.name)
                : (copyState.defender.jwelter.name = null)
            );
          balance = sumNQTStringToNumber(value.balanceNQT);
          if (balance > 1) {
            copyState.defender.jwelter.fighting = true;
          } else {
            copyState.defender.jwelter.fighting = false;
          }
  
          this.api.account
            .getUnconfirmedAccountTransactions(this.ats[8])
            .then(
              result =>
                (copyState.defender.jwelter.unConfTrans =
                  result.unconfirmedTransactions)
            );
  
            
          if (this._isMounted) {
            this.setState({ copyState });
          }
          break;
        case this.ats[9]:
          copyState.defender.jlight.light = owner;
          this.api.account
            .getAccount(owner)
            .then(result =>
              result.name !== undefined
                ? (copyState.defender.jlight.name = result.name)
                : (copyState.defender.jlight.name = null)
            );
          balance = sumNQTStringToNumber(value.balanceNQT);
          if (balance > 1) {
            copyState.defender.jlight.fighting = true;
          } else {
            copyState.defender.jlight.fighting = false;
          }
          this.api.account
            .getUnconfirmedAccountTransactions(this.ats[9])
            .then(
              result =>
                (copyState.defender.jlight.unConfTrans =
                  result.unconfirmedTransactions)
            );
            
  
          if (this._isMounted) {
            this.setState({ copyState });
          }
          break;
          
      default:
        if (this._isMounted) {
          this.setState({ copyState });
        }
    }
  };

  // successBlock = value => this.setState({ curBlock: value.numberOfBlocks });
  showError = () => {
    if (this._isMounted) {
      return this.setState({ error: true });
    }
  };
  render() {
    const { heavy, cruis, middle, welter, light, jheavy, jcruis, jmiddle, jwelter, jlight } = this.state.defender;
    return (
      <React.Fragment>
        <First lang={this.props.lang} fun={this.props.fun} />
        <Second lang={this.props.lang} />
        <Champ
          lang={this.props.lang}
          background={imgHeavy}
          name={heavy.name}
          title={
            this.props.lang === "eng"
              ? "HEAVYWEIGHT (20'000)"
              : "重量级 (20'000)"
          }
          atAddress={this.ats[0]}
          defending={heavy.heavy}
          fighting={heavy.fighting}
          unConfTrans={heavy.unConfTrans}
          ownersList={heavy.ownersChain}
          weight={20000}
          fun={this.props.fun}
          explorer={this.props.explorer}
        />
        <Champ
          lang={this.props.lang}
          name={cruis.name}
          title={
            this.props.lang === "eng"
              ? "CRUISERWEIGHT (10'000)"
              : "次重量级 (10'000)"
          }
          atAddress={this.ats[1]}
          defending={cruis.cruis}
          fighting={cruis.fighting}
          unConfTrans={cruis.unConfTrans}
          ownersList={cruis.ownersChain}
          weight={10000}
          fun={this.props.fun}
          explorer={this.props.explorer}
        />
        <Champ
          lang={this.props.lang}
          name={middle.name}
          background={imgMiddle}
          title={
            this.props.lang === "eng"
              ? "MIDDLEWEIGHT (5'000)"
              : "中量级 (5'000)"
          }
          atAddress={this.ats[2]}
          defending={middle.middle}
          fighting={middle.fighting}
          unConfTrans={middle.unConfTrans}
          ownersList={middle.ownersChain}
          weight={5000}
          fun={this.props.fun}
        />

        <Champ
          lang={this.props.lang}
          name={welter.name}
          title={
            this.props.lang === "eng"
              ? "WELTERWEIGHT (2'000)"
              : "次中量级 (2'000)"
          }
          atAddress={this.ats[3]}
          defending={welter.welter}
          fighting={welter.fighting}
          unConfTrans={welter.unConfTrans}
          ownersList={welter.ownersChain}
          weight={2000}
          fun={this.props.fun}
          explorer={this.props.explorer}
        />
        <Champ
          lang={this.props.lang}
          name={light.name}
          background={imgLight}
          title={
            this.props.lang === "eng" ? "LIGHTWEIGHT (1'000)" : "轻量级 (1'000)"
          }
          atAddress={this.ats[4]}
          defending={light.light}
          fighting={light.fighting}
          unConfTrans={light.unConfTrans}
          ownersList={light.ownersChain}
          weight={1000}
          fun={this.props.fun}
          explorer={this.props.explorer}
        />
        <Champ
          lang={this.props.lang}
          
          name={jheavy.name}
          title={
            this.props.lang === "eng"
              ? "JUNIOR HEAVYWEIGHT (500)"
              : "初重量级 (500)"
          }
          atAddress={this.ats[5]}
          defending={jheavy.heavy}
          fighting={jheavy.fighting}
          unConfTrans={jheavy.unConfTrans}
          ownersList={jheavy.ownersChain}
          weight={500}
          fun={this.props.fun}
          explorer={this.props.explorer}
        />
        <Champ
          lang={this.props.lang}
          name={jcruis.name}
          background={imgHeavy}
          title={
            this.props.lang === "eng"
              ? "JUNIOR CRUISERWEIGHT (200)"
              : "初次重量级 (200)"
          }
          atAddress={this.ats[6]}
          defending={jcruis.cruis}
          fighting={jcruis.fighting}
          unConfTrans={jcruis.unConfTrans}
          ownersList={jcruis.ownersChain}
          weight={200}
          fun={this.props.fun}
          explorer={this.props.explorer}
        />
        <Champ
          lang={this.props.lang}
          name={jmiddle.name}
         
          title={
            this.props.lang === "eng"
              ? "JUNIOR MIDDLEWEIGHT (100)"
              : "初中量级 (100)"
          }
          atAddress={this.ats[7]}
          defending={jmiddle.middle}
          fighting={jmiddle.fighting}
          unConfTrans={jmiddle.unConfTrans}
          ownersList={jmiddle.ownersChain}
          weight={100}
          fun={this.props.fun}
          explorer={this.props.explorer}
        />

        <Champ
          lang={this.props.lang}
          name={jwelter.name}
          background={imgMiddle}
          title={
            this.props.lang === "eng"
              ? "JUNIOR WELTERWEIGHT (50)"
              : "初次中量级 (50)"
          }
          atAddress={this.ats[8]}
          defending={jwelter.welter}
          fighting={jwelter.fighting}
          unConfTrans={jwelter.unConfTrans}
          ownersList={jwelter.ownersChain}
          weight={50}
          fun={this.props.fun}
          explorer={this.props.explorer}
        />
        <Champ
          lang={this.props.lang}
          name={jlight.name}
      
          title={
            this.props.lang === "eng" ? "JUNIOR LIGHTWEIGHT (20)" : "初轻量级 (20)"
          }
          atAddress={this.ats[9]}
          defending={jlight.light}
          fighting={jlight.fighting}
          unConfTrans={jlight.unConfTrans}
          ownersList={jlight.ownersChain}
          weight={20}
          fun={this.props.fun}
          explorer={this.props.explorer}

        />
      
      </React.Fragment>
    );
  }
}

export default AppFun;
